// Custom Dropdown Styling

.dropdown {
  .dropdown-menu {
    font-size: $dropdown-font-size;
    .dropdown-header {
      // @extend .text-uppercase;
      font-weight: 800;
      font-size: 0.65rem;
      color: $gray-500;
    }
  }
}

// Utility class to hide arrow from dropdown

.dropdown.no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}
