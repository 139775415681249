// React vendors
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';

/*
 * EDU-16387 --HP
 * Custom style to hide the triangle on the datepicker as it's broken
 */
.react-datepicker__triangle {
  display: none;
}